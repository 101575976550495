import { version } from "$app/environment"
import { PUBLIC_ENV } from "$env/static/public"
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit"
import * as Sentry from "@sentry/sveltekit"

Sentry.init({
    dsn: "https://8b8e08316a81faa10e4967a195a799a0@o4507685475123200.ingest.de.sentry.io/4508239565094993",

    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
    environment: PUBLIC_ENV,
    release: version,
    enabled: PUBLIC_ENV !== "local",
    beforeSend: (event) => {
        if (
            event.exception?.values &&
            event.exception.values[0] &&
            event.exception.values[0].value?.toLowerCase() === "login required"
        ) {
            // ignore unauth error
        }
        return event
    },
})

const errorFilter = (error) => {
    if (error.error && error.error.error && error.error.error === "login_required") {
        // ignore not logged in issue
    } else {
        throw error
    }
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry(errorFilter)
